<template>
  <router-view v-if="ready"></router-view>
  <div v-else-if="errorMessage" class="notAuthor">
    <img src="../assets/notAuthor.png" />
    <span>{{ errorMessage }}</span>
    <el-button type="primary" class="login-btn" @click="toLogin">登录</el-button>
  </div>
</template>

<script>
import { pick } from "lodash-es";
import { HOME_PATH } from "@/constants";

export default {
  data() {
    return {
      ready: false,
      loading: false,
      errorMessage: "",
    };
  },
  async mounted() {
    if (!localStorage.getItem("token")) {
      this.toLogin();
      return;
    }

    this.loading = this.$loading({
      lock: true,
      text: "页面加载中...",
      spinner: "el-icon-loading",
      customClass: "menu-loading",
      background: "#fff",
    });

    try {
      await Promise.all([this.getUserInfo(), this.getSchoolState(), this.getSchoolList()]);
      await this.getMenuList();
      this.ready = true;
    } catch (error) {
      console.error(error);
    } finally {
      this.loading.close();
    }
  },
  methods: {
    toLogin() {
      let url = "/login";
      try {
        const token = JSON.parse(localStorage.getItem("token"));
        if (token.tenantCode) {
          url = `/${token.tenantCode}/login`;
        }
      } catch (error) {
        // ignore
      }

      this.$store.commit("reset");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace(url);
    },
    async getUserInfo() {
      try {
        const res = await this.$http.get("/v1/saas-basic-data-service/api/user-info/info");
        if (res) {
          this.$store.commit("updateUserData", { ...this.$store.state.userData, ...res });
          return Promise.resolve();
        }

        this.errorMessage = "未找到用户信息";
      } catch (error) {
        this.errorMessage = "获取用户信息失败";
      }

      return Promise.reject();
    },
    // 获取当前学校状态
    async getSchoolState() {
      const res = await this.$http.get("/v1/saas-system-service/api/school-base-data/school-state");
      if (res.state === 0) {
        return Promise.resolve();
      }

      this.errorMessage = res.tenantDisablePrompt || "学校不可用";
      return Promise.reject();
    },
    // 获取校区列表
    async getSchoolList() {
      try {
        const res = await this.$http.get("/v1/saas-basic-data-service/api/school-campus/user");
        if (res.length) {
          this.updateSchoolList(res);
          return Promise.resolve();
        }

        this.errorMessage = "未找到校区信息";
      } catch (error) {
        this.errorMessage = "获取校区信息失败";
      }

      return Promise.reject();
    },
    async getMenuList() {
      try {
        const res = await this.$http.get("/v1/saas-system-service/api/menu/tree");
        const list = (res || []).filter((e) => e.isShow === 1);
        list.unshift({
          name: "工作台",
          id: HOME_PATH,
          isShow: 1,
          menuDtos: [],
          menuUrl: HOME_PATH,
        });
        this.$store.commit("menuListChange", list);
        return Promise.resolve();
      } catch (error) {
        this.errorMessage = "获取菜单信息失败";
      }

      return Promise.reject();
    },
    // 设置当前校区
    updateSchoolList(list) {
      this.$store.commit("onChange", { key: 'campusList', value: list });
      let currentCampus = list[0];
      try {
        const cacheCampus = JSON.parse(sessionStorage.getItem("currentCampus"));
        const _data = list.find(item => item.id === cacheCampus.id);
        if (_data) {
          currentCampus = _data;
        }
      } catch (error) {
        // ignore
      }
      sessionStorage.setItem("currentCampus", JSON.stringify(currentCampus));
    },
  },
};
</script>

<style lang="scss" scoped>
.login-btn {
  margin-top: 20px;
}
.notAuthor {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: calc(100vh - 100px);
  img {
    width: 30%;
  }
  span {
    font-size: 16px;
    width: 50%;
    text-align: center;
  }
}
</style>

