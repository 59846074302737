<template>
  <div>
    <el-popover
      v-model="messShow"
      placement="bottom"
      width="400"
      trigger="hover"
      popper-class="campus-popover"
    >
      <div class="noReadBox">
        <div class="header">
          <div class="title">未读信息</div>
          <div class="readAll" @click="allRead">全部标记为已读</div>
        </div>
        <div class="tabs-box">
          <el-badge
            v-for="item in messTypeGroup"
            :key="item.id"
            :value="item.noReadNum"
            :hidden="item.noReadNum === 0"
            :max="99"
            class="item"
          >
            <el-button
              :class="[activeTag === item.id ? 'tag-select ' : 'tag-noselect']"
              size="small"
              @click="fetchMessList(item.id)"
              >{{ item.name }}</el-button
            >
          </el-badge>
        </div>
        <div class="main-box">
          <div v-show="messageList.length">
            <div
              class="mess-item"
              v-for="item in messageList"
              :key="item.id"
              @click="toPath(item)"
            >
              <div class="item-headr">
                <div>{{ item.eventName }}</div>
                <div>{{ dateFormat(item.realitySendTime) }}</div>
              </div>
              <div v-html="item.context" class="item-content"></div>
            </div>
          </div>
          <div v-show="!messageList.length" class="noMess">
            <img src="@/assets/noMessage.png" alt="" loading="lazy" />
            <div>暂无数据</div>
          </div>
        </div>
      </div>
      <div
        class="bottom-box"
        @click="skipRouter(`/app/userInfo/message?tab=${activeTag}&activeName=1`)"
      >
        <span>查 看 更 多</span>
      </div>
      <div slot="reference" class="bell-box">
        <img src="@/assets/bell.png" alt="" class="bell-img" />
        <div class="dot" v-if="noReadNum"></div>
      </div>
    </el-popover>
  </div>
</template>
<script>
import { HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import dayjs from "dayjs";
export default {
  data() {
    return {
      messShow: false,
      messTypeGroup: [], //消息类型数组
      messageList: [], //消息列表
      noReadNum: false,
      activeTag: "",
    };
  },
  computed: {
    user() {
      return this.$store.state.userData;
    },
    is_Message() {
      return this.$store.state.is_Message;
    },
  },
  async mounted() {
    await this.getNoReadNum();
    await this.fetchMessGroup();
    await this.connect();
  },
  watch: {
    is_Message: {
      handler() {
        this.getNoReadNum();
        this.fetchMessGroup();
      },
      deep: true,
    },
  },
  methods: {
    //开启消息推送
    connect() {
      if (process.env.NODE_ENV == "development") return;
      let url = "/messagehub/message-hub";
      let token = JSON.parse(
        localStorage.getItem("token")
      ).encryptedAccessToken;
      const builder = new HubConnectionBuilder();
      this.connection = builder
        .withUrl(url, {
          accessTokenFactory: () => token,
        })
        .withAutomaticReconnect({ nextRetryDelayInMilliseconds: () => 5000 }) //断线重连
        .build();
      // 开始
      if (this.connection.state !== HubConnectionState.Connected) {
        this.connection.start().then(() => {
          this.connection.invoke("InitlnUser", this.user.userId);
        });
      }
      this.connection.on("Message", (message) => {
        this.$notify({
          title: "收到一条新消息",
          message: message.title,
          duration: 5000,
          customClass:"notificationClass",
          onClick: () => {
            this.toPath(message);
          },
        });
        this.$store.commit("onChange", {
          key: "is_Message",
          value: !this.is_Message,
        });
      });
    },

    async toPath(item) {
      if (item.linkUrl) {
        await this.$http.post(
          "/v1/message-service/api/send-user-message/read-message",
          {
            messageIds: [item.id],
            groupId: null,
          }
        );
        this.$store.commit("onChange", {
          key: "is_Message",
          value: !this.is_Message,
        });
        window.open(item.linkUrl);
        return;
      } else {
        this.skipRouter(`/app/userInfo/message?id=${item.id}&tab=${this.activeTag}&activeName=1`);
      }
    },

    //跳转到消息中心页
    skipRouter(path) {
      this.activeName = "";
      this.messShow = false;
      this.infoShow = false;
      this.$router.push(path);
    },
    //获取当前用户未读的消息数
    async getNoReadNum() {
      try {
        const r = await this.$http.get(
          "/v1/message-service/api/send-user-message/readnum"
        );
        this.noReadNum = r;
      } catch (error) {
        console.log(error);
      }
    },
    //获取消息分组
    async fetchMessGroup() {
      try {
        const r = await this.$http.get(
          "/v1/message-service/api/message-group/tabs"
        );
        if (r.length) {
          const obj = { id: "", name: "全部" };
          obj.noReadNum = r.reduce(
            (sum, item) => sum + (item.noReadNum || 0),
            0
          );
          this.messTypeGroup = [obj, ...r];
          this.fetchMessList(this.messTypeGroup[0].id);
        }
      } catch (error) {
        console.log(error);
      }
    },
    //获取分页列表信息
    async fetchMessList(id) {
      this.activeTag = id;
      const params = {
        GroupId: id,
        isRead: 0,
        SkipCount: 0,
        MaxResultCount: 3,
      };
      try {
        let r = await this.$http.get(
          "/v1/message-service/api/send-user-message",
          { params }
        );
        this.messageList = r.items;
      } catch (error) {
        console.log(error);
      }
    },
    //时间格式化
    dateFormat(date) {
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    //设置全部已读
    allRead() {
      this.$confirm(`确定后将全部消息标记为已读状态，是否确定？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        callback: () => {},
        beforeClose: async (action, ctx, close) => {
          if (action !== "confirm") {
            close();
            return;
          }
          ctx.confirmButtonLoading = true;
          const data = {
            groupId: !this.activeTag ? null : this.activeTag,
          };
          try {
            await this.$http.post(
              "/v1/message-service/api/send-user-message/read-message",
              data
            );
            this.$message.success("操作成功");
            close();
            this.fetchMessList(this.activeTag);
            this.fetchMessGroup();
            this.getNoReadNum();
            this.$store.commit("onChange", {
              key: "is_Message",
              value: !this.is_Message,
            });
          } catch (error) {
            console.error(error);
          }
          ctx.confirmButtonLoading = false;
        },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.bell-box {
  position: relative;

  .bell-img {
    display: inline-block;
    width: 18px;
    height: 20px;
    margin: 0 26px 0 20px;
    cursor: pointer;
  }

  .dot {
    width: 8px;
    height: 8px;
    border-radius: 8px;
    background: #ed4013;
    position: absolute;
    top: -6px;
    right: 22px;
  }
}

.noReadBox {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    margin: 0 16px;
    border-bottom: 1px solid #f5f8fd;

    .title {
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;
      color: #585d69;
    }

    .readAll {
      color: var(--primary);
      font-weight: 400;
      font-size: 14px;
      cursor: pointer;
    }
  }

  .tabs-box {
    margin-top: 16px;
    padding: 0 16px;

    .item {
      :deep(.el-button--small) {
        height: 30px;
        width: 82px;
        margin-right: 8px;
        padding: 0;
        border: none;
        border-radius: 16px;
      }

      :deep(.el-badge__content) {
        right: 25px;
      }
    }
  }

  .main-box {
    margin-top: 16px;
    min-height: 100px;

    .mess-item {
      padding: 16px;
      border-bottom: 1px solid #f5f8fd;
      cursor: pointer;

      .item-headr {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-weight: 400;
        font-size: 14px;
        color: #a4a8b0;
      }

      .item-content {
        margin-top: 12px;
        font-weight: 400;
        font-size: 14px;
        color: #585d69;
      }
    }

    .mess-item:hover {
      background: var(--primary-background-plain-disabled);
    }

    .noMess {
      text-align: center;
      padding-bottom: 50px;

      img {
        width: 150px;
      }

      div {
        color: #a4a8a0;
        font-size: 14px;
        height: 14px;
      }
    }
  }
}

.bottom-box {
  height: 40px;
  background: var(--primary-background-plain-disabled);
  border-radius: 0px 0px 8px 8px;
  text-align: center;
  line-height: 40px;
  cursor: pointer;

  span {
    font-weight: 400;
    font-size: 14px;
    color: var(--primary);
  }
}

.tag-select {
  background: var(--primary-background-hover);
  color: var(--primary);
}

.tag-noselect {
  background: rgba(0, 0, 0, 0.04);
  color: #585d69;
}

</style>
<style>
.notificationClass{
  cursor: pointer;
}
</style>
