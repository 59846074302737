<template>
  <div
    :class="['header-box', user.themeColor === '#009df5' && 'customMainTheme']"
  >
    <div class="header-logo">
      <div class="log-box">
        <img
          :src="user.mainLogoImage || require('/public/img/logonew.png')"
          alt=""
          height="42"
          class="imgbox"
        />
      </div>
    </div>
    <div v-if="hideMenu" class="header-title">{{ title }}</div>
    <div v-else class="menu-wrapper">
      <el-tabs @tab-click="tabClick($event.name)" v-model="activeId">
        <el-tab-pane
          v-for="item in menuList.slice(0, 5)"
          :key="item.id"
          :name="item.id"
        >
          <span slot="label">
            <el-tooltip
              :disabled="item.name.length < 10"
              class="item"
              effect="dark"
              :content="item.name"
              placement="bottom"
              :enterable="false"
            >
              <span> {{ item.name }} </span>
            </el-tooltip>
          </span>
        </el-tab-pane>
      </el-tabs>
      <div class="more" v-if="menuList.length > 5">
        <el-dropdown
          trigger="click"
          :custom-class="'more-box'"
          placement="top"
          ref="dropdown"
        >
          <span class="el-dropdown-link">
            更多<i class="el-icon-arrow-down el-icon--right"></i>
          </span>

          <el-dropdown-menu slot="dropdown">
            <draggable
              v-model="menuList"
              animation="300"
              :disabled="disabled"
              filter=".undraggable"
              :move="onMove"
            >
              <transition-group class="more-dropdown-box">
                <div
                  :class="[
                    'more-dropdown-item',
                    item.id == 'workTable' ? 'undraggable' : '',
                  ]"
                  :style="{
                    cursor:
                      !disabled && item.id == 'workTable'
                        ? 'no-drop'
                        : !disabled
                        ? 'move'
                        : 'pointer',
                  }"
                  v-for="item in menuList"
                  :key="item.id"
                  @click="tabClick(item.id)"
                >
                  <div class="item-img-box">
                    <img
                      :src="item.iconImage"
                      alt=""
                      width="40"
                      height="40"
                      v-if="item.iconImage"
                    />
                    <span v-else class="item-img-icon">{{
                      item?.name[0]
                    }}</span>
                  </div>
                  <span class="item-name">
                    <el-tooltip
                      v-if="item.name.length > 5"
                      effect="dark"
                      :content="item.name"
                      placement="bottom"
                    >
                      <span class="item-name-span">{{ item.name }}</span>
                    </el-tooltip>
                    <span v-else class="item-name-span">{{ item.name }}</span>
                  </span>
                </div>
              </transition-group>
            </draggable>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="header-rightBox">
      <div class="rightSide">
        <div class="menu-add">
          <div class="search">
            <el-input
              type="text"
              size="small"
              prefix-icon="el-icon-search"
              placeholder="搜索"
              v-model="content"
              class="search-item"
              clearable
            ></el-input>
          </div>
          <div class="icon-box">
            <message></message>
            <img src="@/assets/headset.png" alt="" class="headset-img" />
          </div>
        </div>
      </div>
      <div class="line"></div>
      <div class="campus-box" v-if="campusList.length > 1">
        <el-popover
          placement="bottom"
          width="248"
          trigger="hover"
          popper-class="campus-popover"
        >
          <div class="campus-container">
            <div class="container-header">切换组织/团队</div>
            <div class="campus-main">
              <div
                class="floor"
                v-for="item in campusList"
                :key="item.id"
                :style="{
                  background: currentCampus.id === item.id ? '#f3f5fc' : '#fff',
                }"
                @click="switchCampus(item)"
              >
                <div class="campus-item">
                  <div class="campus-name">
                    {{ item.name }}
                  </div>
                  <div class="campus-type">
                    {{ item.typeName }}
                  </div>
                </div>
                <div class="check-icon" v-if="currentCampus.id === item.id">
                  <i
                    class="iconfont icon-duihao"
                    style="color: var(--primary)"
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <span class="el-dropdown-link" slot="reference">
            {{ currentCampus.name }}<i class="el-icon-sort" />
          </span>
        </el-popover>
      </div>
      <div class="line" v-if="campusList.length > 1"></div>
      <el-popover
        v-model="infoShow"
        placement="bottom"
        width="248"
        trigger="hover"
        popper-class="campus-popover"
      >
        <div class="personal-box">
          <div class="box-header">
            <div>
              <img :src="user.userImage || require('../../assets/user.png')" />
            </div>
            <div>
              <div class="name">{{ user.name }}</div>
              <div class="profession">
                {{ userType[user.userType] || "其他" }}
              </div>
            </div>
          </div>
          <div class="menu-box">
            <div
              class="menu-item"
              @click="skipRouter('/app/userInfo/index?activeName=1')"
            >
              <div class="menu-title">个人中心</div>
              <div class="right-row">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div
              class="menu-item"
              @click="skipRouter('/app/userInfo/index?activeName=3')"
            >
              <div class="menu-title">修改密码</div>
              <div class="right-row">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div
              class="menu-item"
              @click="skipRouter('/app/userInfo/index?activeName=2')"
            >
              <div class="menu-title">主题配置</div>
              <div class="right-row">
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>
            <div class="menu-item" @click="logout">
              <div class="menu-title log-out">退出系统</div>
            </div>
          </div>
        </div>
        <div class="user" slot="reference">
          <div class="user-center">
            <span class="el-dropdown-link">
              <div class="user-box">
                <img
                  :src="user.userImage || require('../../assets/user.png')"
                />
              </div>
              <span>{{ user.name }}</span>
              <i class="el-icon-caret-bottom" />
            </span>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
</template>
<script>
import message from "@/components/message.vue";
import draggable from "vuedraggable";
import { HOME_PATH } from "@/constants";
import { findMenu } from "@/utils";

export default {
  props: {
    hideMenu: Boolean,
    title: String, // 菜单隐藏时展示的标题
  },
  components: {
    draggable,
    message,
  },
  data() {
    return {
      disabled: true,
      activeId: "",
      currentCampus: (sessionStorage.getItem("currentCampus") &&
        JSON.parse(sessionStorage.getItem("currentCampus"))) || {
        id: "",
        name: "",
      },
      loading: false,
      userType: { 0: "系统", 1: "教职工", 2: "学生", 3: "家长", 4: "企业" },
      content: "",
      infoShow: false,

    };
  },
  watch: {
    "$route.fullPath": {
      immediate: true,
      handler(val) {
        const path =
          val === HOME_PATH
            ? HOME_PATH
            : val.replace("/app/", "").replace("#/", "/").split("?")[0];
        for (const menu of this.$store.state.menuList) {
          if (menu.menuUrl === path || findMenu(menu.menuDtos, path)) {
            this.activeId = menu.id;
            return;
          }
        }

        this.activeId = "";
      },
    },
  },
  computed: {
    user() {
      return this.$store.state.userData;
    },
    menuList() {
      return this.$store.state.menuList;
    },
    campusList() {
      return this.$store.state.campusList;
    },
  },
  mounted() {
    window.addEventListener("message", (event) => {
      if (event.data.type === "updateCampusList") {
        this.$store.commit("onChange", { key: "campusList", value: event.data.value });
        this.currentCampus = JSON.parse(sessionStorage.getItem("currentCampus"));
      }
    });
  },
  methods: {
    skipRouter(path) {
      this.infoShow = false;
      this.$router.push(path);
    },
    tabClick(menuId) {
      this.$refs.dropdown?.hide();
      const menu = this.menuList.find((val) => val.id === menuId);
      this.jumpMenuFirstLeaf(menu);
    },
    jumpMenuFirstLeaf(menu) {
      if (menu.menuDtos.length) {
        this.jumpMenuFirstLeaf(menu.menuDtos[0]);
      } else {
        if (/^https?:\/\//.test(menu.menuUrl)) {
          window.open(menu.menuUrl);
          return;
        }

        this.$router.push(
          menu.menuUrl === HOME_PATH
            ? HOME_PATH
            : `/app/${menu.menuUrl.replace("/", "#/")}`
        );
      }
    },
    async logout() {
      this.$loading({
        lock: true,
        text: "",
        spinner: "el-icon-loading",
        customClass: "menu-loading",
        background: "#fff",
      });
      await this.$http({
        url: "/v1/identity-service/api/account/logout",
        method: "post",
      });
      const tenantCode = this.user.tenantCode;
      this.$store.commit("reset");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace((tenantCode ? `/${tenantCode}` : "") + "/login");
    },
    onMove(e) {
      if (e.relatedContext.element.id == "workTable") return false;
      return true;
    },
    // 切换校区
    async switchCampus(item) {
      if (item.id == this.currentCampus.id) return;

      this.loading = this.$loading({
        lock: true,
        text: "页面加载中...",
        spinner: "el-icon-loading",
        customClass: "menu-loading",
        background: "#fff",
      });
      const res = await this.$http.post(
        `/v1/saas-basic-data-service/api/school-campus/${item.id}/verify`
      );
      this.loading.close();
      if (res) {
        this.saveCampus(item);
        sessionStorage.setItem("changemakeTai", 1);
        location.reload();
        return;
      }

      this.$confirm("检测到校区不存在或已删除，即将重载页面！", "提示", {
        confirmButtonText: "确定",
        type: "warning",
        showCancelButton: false,
        beforeClose: (action, ctx, close) => {
          close();
          sessionStorage.setItem("changemakeTai", 1);
          location.reload();
        },
      });
    },
    // 保存校区
    saveCampus(item) {
      this.currentCampus = item;
      sessionStorage.setItem(
        "currentCampus",
        JSON.stringify(this.currentCampus)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown-link {
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  cursor: pointer;
}

.customMainTheme {
  background-image: linear-gradient(45deg, #009df5, #77cffa, #4bd7c0);
}

.header-box {
  flex: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  box-shadow: 0px 2px 8px 0px rgba(97, 121, 167, 0.08);
  background-color: var(--primary);

  .header-logo {
    display: flex;
    justify-content: center;
    align-items: center;

    .school-name {
      color: #fff;
      font-size: 22px;
    }
  }

  .header-title {
    flex: 1;
    margin-right: 40px;
    font-family: SourceHanSansCN;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-rightBox {
    display: flex;
    align-items: center;
  }
}

.rightSide {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.log-box {
  max-width: 300px;
  text-align: center;
  padding: 0px 10px;
  display: flex;
  align-items: center;
}

:deep(.el-tabs__item) {
  height: 38px !important;
  max-width: 210px;
  color: #ffffff;
  font-size: 16px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;

  .el-tooltip {
    display: inline-block;
    max-width: 145px !important;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}

:deep(.el-tabs__item:hover) {
  color: #fff;
  overflow: hidden;
}

:deep(.el-tabs) {
  .el-tabs__active-bar {
    height: 2px !important;
    background: #fff !important;
    bottom: 4px !important;
  }

  .is-active {
    color: #fff !important;
  }
}

.menu-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  white-space: nowrap;

  .el-tabs {
    height: 40px;

    :deep(.el-tabs__item) {
      padding: 0 !important;
      padding-left: 40px !important;
    }
  }
}

.menu-add {
  display: flex;

  :deep(.el-input__inner) {
    width: 188px;
    height: 32px;
    background: rgba(255, 255, 255, 0.32);
    border-radius: 16px;
    border: none;
    color: #fff;
  }

  :deep(.el-input__prefix) {
    color: #fff;
  }

  :deep(.el-input__suffix) {
    color: #fff;
  }

  .icon-box {
    display: flex;
    margin-top: 6px;

    .headset-img {
      width: 18px;
      height: 20px;
      margin-right: 27px;
    }
  }
}

:deep(.el-tabs__nav-wrap::after) {
  color: yellow !important;
  height: 0 !important;
}

.campus-box {
  margin-left: 19px;
  text-wrap: nowrap;
}

.user {
  display: flex;
  margin-right: 20px;

  .user-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    margin-right: 12px;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .user-center {
    display: flex;
    align-items: center;
  }

  .el-dropdown-link {
    display: flex;
    align-items: center;
    text-wrap: nowrap;
    i:not(.el-icon-user-solid) {
      color: rgba(255, 255, 255, 0.7);
      margin-left: 12px;
    }
  }
}

.line {
  width: 1px;
  height: 18px;
  background: #fff;
  border-radius: 2px;
}

.more {
  font-size: 14px;
  margin: 0 40px 2px;
  color: #303133;
  cursor: pointer;

  .el-dropdown {
    color: #303133;
  }
}

.el-icon-sort {
  margin-left: 8px;
  margin-right: 20px;
  transform: rotate(90deg);
}

.tab-peon {
  color: #a9afbc;
  width: 100%;
  background-color: #ffffff !important;
}

.more-dropdown-box::-webkit-scrollbar {
  width: 0;
}

.more-dropdown-box {
  width: 630px;
  min-height: 300px;
  max-height: 450px;
  overflow-y: auto;
  display: inline-block;
  position: relative;

  .more-dropdown-item {
    width: 65px;
    height: 60px;
    margin: 10px;
    text-align: center;
    display: inline-block;

    .item-img-icon {
      display: inline-block;
      margin-top: 4px;
      margin-bottom: 6px;
      width: 32px;
      line-height: 32px;
      border: 1px solid var(--primary);
      border-radius: 12px;
      font-weight: 900;
      color: var(--primary);
    }

    .item-img-box {
      width: 80px;
      display: flex;
      justify-content: center;
    }

    .item-name {
      font-size: 14px;
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-tooltip {
        display: inline-block;
        width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .item-name-span {
      display: inline-block;
      width: 80px;
    }
  }
}

.more-dropdown-sort {
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 12px;
  color: #a4a8b0;
  cursor: pointer;
  z-index: 999;
}

.imgbox {
  width: fit-content;
  height: 42px;
}

.personal-box {
  padding: 16px;

  .box-header {
    display: flex;
    height: 48px;

    img {
      margin-right: 17px;
      width: 48px;
      height: 48px;
      border-radius: 6px;
    }

    .name {
      font-weight: 400;
      font-size: 18px;
      color: #585d69;
    }

    .profession {
      width: 51px;
      height: 18px;
      border-radius: 4px;
      background: rgba(0, 157, 245, 0.06);
      color: var(--primary);
      font-family: Alibaba-PuHuiTi;
      font-size: 12px;
      line-height: 18px;
      text-align: center;
    }
  }

  .menu-box {
    margin-top: 16px;

    .menu-item {
      display: flex;
      justify-content: space-between;
      height: 40px;
      line-height: 40px;
      cursor: pointer;

      .menu-title {
        font-weight: 400;
        font-size: 14px;
        color: #585d69;
      }

      .log-out {
        color: #ff8b8b;
      }
    }
  }
}

.campus-container {
  .container-header {
    padding: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #585d69;
  }

  .campus-main {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    max-height: 400px;
    overflow-y: scroll;

    .floor {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 64px;
      padding: 0 16px;
      box-shadow: inset 0px -1px 0px 0px #f5f8fd;
      cursor: pointer;

      .campus-item {
        .campus-name {
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #585d69;
        }

        .campus-type {
          margin-top: 8px;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          color: #a4a8b0;
        }
      }

      .check-icon {
        img {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .campus-main::-webkit-scrollbar {
    display: none;
  }
}

:deep(.el-input--prefix .el-input__inner::placeholder) {
  color: #fff;
}

:deep(.el-input--prefix .el-input--suffix) {
  color: #fff;
}

:deep(.el-input .el-input__clear) {
  color: #fff;
}
</style>
<style>
.campus-popover {
  padding: 0px !important;
  border-radius: 8px !important;
}
</style>
